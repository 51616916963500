/* eslint-disable import/first,import/newline-after-import */
import "@/javascript/shared/entrypoints/_polyfills"
import { controllerIdentifierFromPath } from "@/javascript/shared/controllers/helper"
import { application } from "@/javascript/shared/entrypoints/_gp_base"
import "@/javascript/lib/serviceworker_companion"
import.meta.glob("@/javascript/site/**/*.js", { eager: true })

// CSS
import.meta.glob("@/stylesheets/shared/common/**/*.scss", { eager: true })
import.meta.glob("@/stylesheets/shared/ui/**/*.scss", { eager: true })
import.meta.glob("@/stylesheets/site/*.scss", { eager: true })
import.meta.glob("@components/!(admin|couriers|markets)/**/component.scss", { eager: true })

// Stimulus
const controllers = import.meta.glob(
  [
    "@/javascript/site/controllers/**/*controller.[jt]s",
    "@components/!(admin|couriers|markets)/**/component.site.[jt]s",
    "@components/!(admin|couriers|markets)/**/*controller.site.[jt]s",
  ],
  { eager: true }
)
Object.entries(controllers).forEach(([path, definition]) => {
  const identifier = controllerIdentifierFromPath(path)
  application.register(identifier, definition.default)
})
