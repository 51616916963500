import { Controller } from "@hotwired/stimulus"
import { html2dom, productImagePath, pictureImgHtml } from "@/javascript/lib/utils"

export default class extends Controller {
  static targets = ["dot", "img"]
  static values = { currentSlideIndex: Number }

  connect() {
    this.images = this.element.dataset.images.split(",")

    if (this.images.length <= 1) {
      return
    }

    this.timeoutId = null

    this.element.addEventListener("mouseenter", this.#mouseenter, { passive: true })
    this.element.addEventListener("mouseleave", this.#mouseleave, { passive: true })
  }

  disconnect() {
    this.element.removeEventListener("mouseenter", this.#mouseenter)
    this.element.removeEventListener("mouseleave", this.#mouseleave)
  }

  selectSlide(event) {
    const { index } = event.target.dataset

    // Необходим для мягкой активации
    this.currentSlideIndexValue = index

    this.dotTargets.forEach((el) => el.classList.toggle("_active", el.dataset.index === index))
    this.imgTargets.forEach((el) => el.classList.toggle("_active", el.dataset.index === index))
  }

  #prepareDom(target, images) {
    if (target.querySelector("._panels")) {
      return
    }

    let dots = ""
    let panels = ""
    let slides = ""

    images.forEach((img, i) => {
      const active = i === 0 ? "_active" : ""
      const thumb2x = productImagePath(target.dataset.productId, img, "thumb2x")

      dots += `<div class="_dot ${active}" data-index=${i} data-products--product-slider-target="dot"></div>`
      panels += `<div class="_panel ${active}" data-index=${i} data-action="mouseenter->products--product-slider#selectSlide"></div>`
      slides += pictureImgHtml(thumb2x, {
        class: "_slide",
        "data-index": i,
        "data-products--product-slider-target": "img",
      })
    })

    const content = html2dom(`
      <div class="_slides">
          ${slides}
      </div>
      <div class="_panels">
          ${panels}
      </div>
      <div class="_dots">
          ${dots}
      </div>`)

    target.appendChild(content)
  }

  #mouseenter = (event) => {
    this.timeoutId = setTimeout(() => {
      this.element.classList.add("_slider-active")
      this.#prepareDom(event.target, this.images)
    }, 300)
  }

  #mouseleave = (_event) => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    this.currentSlideIndexValue = ""

    this.element.classList.remove("_slider-active")
    this.dotTargets.forEach((el) => el.classList.remove("_active"))
    this.imgTargets.forEach((el) => el.classList.remove("_active"))
  }
}
